<template>
  <div class="list-info" id="watermark">
    <top-bar title="生育登记" :left="true"></top-bar>
    <div class="search">
      <input v-model="searchValue" type="search" value="搜索" placeholder="请输入要查询的姓名" class="search-content" @keyup.enter="onSearch">
      <img :src="require('@/assets/img/clear.png')" class="search-clear" @click="onCancel">
      <div class="search-btn" @click="onSearch">搜索</div>
    </div>
    <div class="filterBar">
      <van-row>
        <van-col span="8">
          <p @click="dateBarShow=!dateBarShow" :style="{color:dateBarShow?'#387FF5':'#666666' }">登记时间
            <img :src="require(`@/assets/img/${dateBarShow?'pull-down-select':'pull-down'}.png`)" alt="" class="pull-icon">
          </p>
        </van-col>

        <van-col span="8">
          <p @click="dateBarShow1=!dateBarShow1" :style="{color:dateBarShow1?'#387FF5':'#666666' }">预产日期
            <img :src="require(`@/assets/img/${dateBarShow1?'pull-down-select':'pull-down'}.png`)" alt="" class="pull-icon">
          </p>
        </van-col>
        <van-col span="8">
          <p @click="moreShow = !moreShow" >
            更多
            <img :src="require(`@/assets/img/${moreShow?'pull-down-select':'pull-down'}.png`)" alt="" class="pull-icon">
          </p>
        </van-col>
      </van-row>
    </div>
    <van-popup v-model="communityShow" position="bottom">
      <van-picker title="请选择" show-toolbar :columns="communityList" value-key="name" @confirm="communityConfirm" @cancel="communityShow = false"  />
    </van-popup>
      <!--    登记时间-->
    <div class="dateBar" v-show="dateBarShow">
      <van-row>
        <van-col span="8" @click="beginDateShow = !beginDateShow"><span>{{selectDate.beginDate == ''?'最早':selectDate.beginDate}}</span></van-col>
        <van-col span="2" ><span>-</span></van-col>
        <van-col span="8" @click="endDateShow = !endDateShow"><span>{{selectDate.endDate== ''?'至今':selectDate.endDate}}</span></van-col>
        <van-col span="3" @click="dateClose"><span>重置</span></van-col>
        <van-col span="3" @click="changeDate"><span>确定</span></van-col>
      </van-row>
    </div>
    <van-popup v-model="beginDateShow" position="bottom">
      <van-datetime-picker @confirm="beginDateConfim" @cancel="beginDateShow = false"  type="date"
                           :formatter="formatDate" :min-date="new Date(2017, 0, 1)" :max-date="new Date()"/>
    </van-popup>
    <van-popup v-model="endDateShow" position="bottom">
      <van-datetime-picker @confirm="endDateConfim" @cancel="endDateShow = false" type="date" :formatter="formatDate"
                           :min-date="new Date(2017, 0, 1)" :max-date="new Date(2025, 10, 1)"/>
    </van-popup>
      <!--    预产日期-->
    <div class="dateBar" v-show="dateBarShow1">
      <van-row>
        <van-col span="8" @click="beginDateShow1 = !beginDateShow1"><span>{{selectDate.beginDate1 == ''?'最早':selectDate.beginDate1}}</span></van-col>
        <van-col span="2" ><span>-</span></van-col>
        <van-col span="8" @click="endDateShow1 = !endDateShow1"><span>{{selectDate.endDate1== ''?'至今':selectDate.endDate1}}</span></van-col>
        <van-col span="3" @click="dateClose"><span>重置</span></van-col>
        <van-col span="3" @click="changeDate"><span>确定</span></van-col>
      </van-row>
    </div>
    <van-popup v-model="beginDateShow1" position="bottom">
      <van-datetime-picker @confirm="beginDateConfim1" @cancel="beginDateShow1 = false"  type="date"
                           :formatter="formatDate" :min-date="new Date(2017, 0, 1)" :max-date="new Date()"/>
    </van-popup>
    <van-popup v-model="endDateShow1" position="bottom">
      <van-datetime-picker @confirm="endDateConfim1" @cancel="endDateShow1 = false" type="date" :formatter="formatDate"
                           :min-date="new Date(2017, 0, 1)" :max-date="new Date(2025, 10, 1)"/>
    </van-popup>
      <!--      更多筛选条件-->
    <van-popup v-model="moreShow" position="right" :style="{ width: '90%', height: '100%'}" @open="moreClose">
      <div style="margin-bottom: 50px;">
        <van-cell title="申请类型">
          <span style="color: #387FF5">{{ show.type }}</span>
        </van-cell>
        <van-grid :border="false" :column-num="3">
          <van-grid-item v-for="item in typeList" :key="item.value">
            <van-tag size="large" @click="typeFn(item)" :class="item.label === show.type?'checkedTag':'commonTag'">{{ item.label }}
            </van-tag>
          </van-grid-item>
        </van-grid>

        <van-cell title="申请方式">
          <span style="color: #387FF5">{{ show.applyType }}</span>
        </van-cell>
        <van-grid :border="false" :column-num="3">
          <van-grid-item v-for="item in applyList" :key="item.value">
            <van-tag size="large" @click="applyFn(item)" :class="item.label === show.applyType?'checkedTag':'commonTag'">{{ item.label }}
            </van-tag>
          </van-grid-item>
        </van-grid>

        <van-cell title="是否怀孕">
          <span style="color: #387FF5">{{ show.pregnant }}</span>
        </van-cell>
        <van-grid :border="false" :column-num="3">
          <van-grid-item v-for="item in preList" :key="item.value">
            <van-tag size="large" @click="preFn(item)" :class="item.label === show.pregnant?'checkedTag':'commonTag'">{{ item.label }}
            </van-tag>
          </van-grid-item>
        </van-grid>
      </div>
      <van-row>
        <van-col span="12">
          <van-button block @click="moreCancel">重置</van-button>
        </van-col>
        <van-col span="12">
          <van-button type="info" block @click="moreConfirm">确定</van-button>
        </van-col>
      </van-row>
    </van-popup>
    <div class="addBtn" @click="goAdd">
      <img src="@/assets/img/add.png" alt="">
    </div>
    <div class="cont">
      <!-- <van-pull-refresh v-model="pullLoading" success-text="刷新成功" @refresh="onPullDownRefresh"> -->
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getDataList" v-if="dataList.length > 0" offset="10">
          <div class="totalCount">共有&nbsp;<span style="color:#387FF5">{{totalCount}}</span>&nbsp;条数据</div>
          <div class="list-item" v-for="item in dataList" :key="item.id" @click="getInfo(item.id)">
            <div class="title">
              <div class="title-left">
                {{item.maleName + ',' + item.femaleName}}
              </div>
              <div class="title-right">
                {{item.typeStr}}
              </div>
            </div>
            <div class="content">
              <van-image :src="item.url?item.url:require('@/assets/img/flower.png')" class="content-img">
                <template slot="error">
                  <img :src="require('@/assets/img/flower.png')" alt="" class="content-img">
                </template>
                <template slot="loading">
                  <img :src="require('@/assets/img/flower.png')" alt="" class="content-img">
                </template>
              </van-image>
              <div class="content-text">
                <div class="top-text"><span>申请方式：</span>{{item.applyTypeStr}}</div>
                <div class="bottom-text" style="color: #3D7EFE;">{{item.pregnantStr}}</div>
              </div>
              <van-icon name="arrow" color="#666" class="content-icon"/>
            </div>
          </div>
        </van-list>
        <van-image v-else :src="require('@/assets/img/empty.png')" style="margin-left:10%;width:80%;"></van-image>
      <!-- </van-pull-refresh> -->
    </div>
  </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'

import {formatterDate} from '@/utils/utils'
import { getDictTree } from '@/utils/common.js'
import { mapMutations } from 'vuex'
export default {
  components :{
    topBar
  },
  data() {
    return {
      pullLoading: false,
      communityName: '所属社区',
      loading: false,
      finished: false,
      endDateShow: false,
      beginDateShow: false,
      endDateShow1: false,
      beginDateShow1: false,
      dateBarShow: false,
      dateBarShow1: false,
      communityShow: false,
      moreShow:false,
      page: 0,
      limit: 10,
      searchValue: '',
      selectDate: {
        endDate: '',
        beginDate: '',
        endDate1: '',
        beginDate1: '',
        type: '',
        applyType: '',
        pregnant: ''
      },
      communityList: [],
      totalCount: 0,
      dataList: [],
      type: '',
      show:{
        type: '',
        applyType: '',
        pregnant: ''
      },
      typeList: [{
        label: '一孩申请',
        value: '1',
      },
        {
          label: '二孩申请',
          value: '2',
        }],
      applyList: [],
      preList: [{
        label: '已孕',
        value: '1',
      },
        {
          label: '未孕',
          value: '0',
        }],
    };
  },
  methods: {
    ...mapMutations,
    getInfo (id) {
      this.$router.push({path: '/pregnancy-add', query: {id: id}})
    },
    getDataList () {
      this.page++
      this.$toast.loading({duration: 0,message: '加载中...',forbidClick: true,});
      this.$http({
        url: this.$http.adornUrl('/wxapp/pregnancy/list'),
        method: 'post',
        params: this.$http.adornParams({
          page: this.page,
          limit: this.limit,
          name: this.searchValue,
          regDateStartTime: this.selectDate.beginDate,
          regDateEndDates: this.selectDate.endDate,
          childDateStartTime: this.selectDate.beginDate1,
          childDateEndDates: this.selectDate.endDate1,
          orgId: this.$globalData.userInfo.orgId,
          type: this.selectDate.type,
          applyType: this.selectDate.applyType,
          pregnant:this.selectDate.pregnant
        })
      }).then(({data})=> {
        if (data.code == 0) {
          this.pullLoading = false
          this.totalCount = data.page.totalCount
          if (this.dataList.length == data.page.totalCount) {
            this.finished = true
          }
          // let dataList = data.page.list
          // dataList.map(item => {
          //   if (item.inspectionTime) {
          //     item.inspectionTime = item.inspectionTime.split(' ')[0]
          //   }
          // })
          this.dataList = this.dataList.concat(data.page.list)
          // 加载状态结束
          this.loading = false;
          this.$toast.clear()
        }
        this.$toast.clear()
      },err=> {this.$toast.clear()})
    },
    getDeviceTypeList () {
      let that = this;
      getDictTree({ code: "pregnancyType" }, function (e) {
        that.applyList = e
      });
    },
    formatDate (type, val) {
      if (type === 'year') {
        return val + '年'
      }
      if (type === 'month') {
        return val + '月'
      }
      if (type === 'day') {
        return val + '日'
      }
      return val
    },
    onPullDownRefresh(e) {
      this.finished=false
      this.dataList = []
      this.page = 0;
      this.getDataList()
    },
    onSearch () {
      this.page = 0
      this.dataList = []
      this.getDataList()
    },
    onCancel () {
      this.page = 0
      this.dataList = []
      this.searchValue = ''
      this.getDataList()
    },
    beginDateConfim (value) {
        let d = new Date(this.selectDate.endDate)
        if (this.selectDate.endDate != '' && d.getTime(d) < value.getTime(value)) {
          return this.$toast.fail('开始时间不能比结束时间大哦')
        }
        this.selectDate.beginDate = formatterDate(value)
        this.beginDateShow = false
    },
    beginDateConfim1 (value) {
      let d = new Date(this.selectDate.endDate1)
      if (this.selectDate.endDate1 != '' && d.getTime(d) < value.getTime(value)) {
        return this.$toast.fail('开始时间不能比结束时间大哦')
      }
      this.selectDate.beginDate1 = formatterDate(value)
      this.beginDateShow1 = false
    },
    endDateConfim (value) {
      let d = new Date(this.selectDate.beginDate)
      if (this.selectDate.beginDate != '' && d.getTime(d) > value.getTime(value)) {
        return this.$toast.fail('结束时间不能比开始时间小哦')
      }
      this.selectDate.endDate = formatterDate(value)
      this.endDateShow = false
    },
    endDateConfim1 (value) {
        let d = new Date(this.selectDate.beginDate1)
        if (this.selectDate.beginDate1 != '' && d.getTime(d) > value.getTime(value)) {
          return this.$toast.fail('结束时间不能比开始时间小哦')
        }
        this.selectDate.endDate1 = formatterDate(value)
        this.endDateShow1 = false
    },
    typeFn(n){
      this.show.type = n.label
      this.selectDate.type = n.value
    },
    applyFn(n){
      this.show.applyType = n.label
      this.selectDate.applyType = n.value
    },
    preFn(n){
      this.show.pregnant = n.label
      this.selectDate.pregnant = n.value
    },
    moreClose() {

    },
    moreCancel() {
      //重置
      this.show.type = ''
      this.show.applyType = ''
      this.show.pregnant = ''
      this.selectDate.type = ''
      this.selectDate.applyType = ''
      this.selectDate.pregnant = ''
      this.moreShow = false
      this.dataList = []
      this.page = 0
      this.getDataList()
    },
    moreConfirm() {
      this.moreShow = false
      this.dataList = []
      this.page = 0
      this.getDataList()
    },
    changeDate () {
      this.dataList = []
      this.page = 0
      this.getDataList()
    },
    dateClose () {
      this.selectDate = {
        endDate: '',
        beginDate: '',
        endDate1: '',
        beginDate1: '',
        type: '',
        applyType: '',
        pregnant: ''
      }
    },
    communityConfirm (value,index) {
      this.page = 0
      this.dataList = []
      this.communityName = value.name
      this.communityId = value.id
      this.getDataList()
      this.communityShow = false
    },
    goAdd () {
      this.$router.push('/pregnancy-add')
    }
  },
  created () {
    this.$watermark.set(this.$globalData.userInfo.userName, this.$globalData.userInfo.mobile)
    this.$toast.loading({
      duration: 0,
      message: '加载中...',
      forbidClick: true,
    });
    this.userId = this.$globalData.userInfo.userId
    this.getDeviceTypeList()
    setTimeout(() => {
      this.getDataList()
    }, 300);

  },
}
</script>

<style lang="scss" scoped>
.van-popup {
  .van-tag {
    text-align: center;
    display: block;
    width: 190px;
    height: 70px;
    line-height: 70px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 10px;
  }
}
.checkedTag {
  background-color: rgba(0, 122, 255, 0.1);
  color: #387ff5;
  border: 1px solid #387ff5;
}
.commonTag {
  background-color: #f0f2f5;
  color: #333;
}
</style>